.dot {
  position: absolute;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 99;

  svg {
    height: 50px;
    width: 50px;

    .cls-1 {
      fill: #1d4f48;
    }
  }
}
