div.left-arrow {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 60px;
  cursor: pointer;
  z-index: 100;

  svg {
    filter: drop-shadow(0px 0px 7.5px rgba(0, 0, 0, 0.6));
  }

  @media (max-width: 768px) {
    top: 87.5%;
    width: 45px;
  }

  @media (max-height: 700px) {
    top: 75%;
    width: 45px;
  }
}
