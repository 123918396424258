body {
  margin: 0;
}

div#flow-graphic-app {
  height: 100vh;
  position: relative;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
}
