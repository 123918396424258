main.flow-app {
  position: relative;
  max-height: 100%;
  height: 100%;
  width: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  cursor: grab;

  font-family: Arial, Helvetica, sans-serif;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    max-height: 100%;
    min-height: 100%;
    height: 100%;

    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: none !important;
  }
}
