main.flow-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  img{
    object-fit: contain;
  }
  
  @media (max-width: 676px) {
    overflow-y: scroll;
  }

  span.ksmol {
    font-size: 8pt !important;
  }

  span.smol {
    font-size: 8pt !important;

    @media (max-width: 676px) {
      font-size: 7pt !important;
    }
  }

  div.modal-window {
    background-color: transparent;
    // background-color: #b19cd9;
    position: relative;

    margin: 10% 10%;

    // This is for mobile
    @media (max-width: 676px) {
      display: flex;
      flex-direction: column;
      margin: 10% 5%;
      padding-bottom: 3rem;
      font-family: sans-serif;
      font-size: 12pt;

      div.text {
        order: 1;
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        background-color: #ededed;

        border-radius: 15px;

        div.title {
          display: flex;
          align-items: center;
          margin-left: 12px;
          margin-top: 5px;

          h2 {
            margin-left: 12px;
            font-size: 24pt;
            font-weight: 400;
            vertical-align: center;
            margin-top: 0;
            margin-bottom: 0;
            font-family: sans-serif;
            font-weight: bold;
            margin-right: 22px;
          }

          svg {
            width: 30px;
          }
        }

        & > span {
          margin-top: 25px;
          font-size: 1rem;
          line-height: 1.3rem;

          @supports (-webkit-touch-callout: none) {
            font-size: 0.9rem;
          }
        }

        span.cta {
          font-weight: bold;
          line-height: 1.2rem;
        }

        div.buttons {
          margin-top: 15px;
          display: flex;
          justify-content: center;
          width: 100%;

          a,
          button {
            margin: 10px 10px;
            font-size: 1.3rem;
            background-color: #47b372;
            text-transform: uppercase;
            color: white;
            padding: 7.5px;
            border: none;
            cursor: pointer;

            &:active {
              color: lightgrey;
            }
          }
        }
      }

      div.graphic {
        margin-top: 15rem;
        order: 0;
        background-color: #47b372;
        border-radius: 15px;
        padding: 15px;
        padding-bottom: 11px;
        margin-bottom: 1rem;

        img {
          height: 100%;
          width: 100%;
          border-radius: 5px;
        }
      }

      div.products {
        order: 10;
        margin-top: 11px;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border-radius: 15px;
        justify-content: center;

        background-color: #1d4f48;

        a {
          position: relative;
          margin: 15px 7.5px;
          margin-bottom: 35px;
          background-color: white;
          height: 90px;
          width: 100px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          text-decoration: none;

          img {
            max-width: 100% !important;
            aspect-ratio: 1/1;
          }

          span {
            font-family: sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 9pt;
            text-align: center;
            position: absolute;
            bottom: -35px;
            height: 35px;
            width: 100px;
            max-width: 1000px;
            word-break: break-word;
            background-color: #47b372;
            color: white;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      button.close {
        position: absolute;
        right: 15px;
        font-weight: 500;
        color: #47b372;
        margin: 0;
        padding: 0;
        border: none;
        font-size: 2rem;
        cursor: pointer;
        background-color: transparent;

        &:active {
          color: lightgrey;
        }
      }
    }

    // This is for desktop
    @media (min-width: 676px) {
      div.text {
        max-width: 1000px;
        min-height: 375px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        background-color: #ededed;
        font-family: sans-serif;
        font-size: 12pt;

        border-radius: 1px 50px 50px 50px;

        div.title {
          display: flex;
          align-items: center;
          margin-left: 12px;
          margin-top: 5px;

          h2 {
            margin-left: 12px;
            font-size: 24pt;
            font-weight: 400;
            vertical-align: center;
            margin-top: 0;
            margin-bottom: 0;
            font-family: sans-serif;
            font-weight: bold;
          }

          svg {
            width: 30px;
          }
        }

        & > span {
          margin-top: 15px;
          padding-left: 62px;
          padding-right: 62px;
        }

        span.cta {
          margin-top: 22px;
          font-weight: bold;
        }

        button.close {
          position: absolute;
          right: 25px;
          top: 25px;
          font-weight: 500;
          color: #47b372;
          margin: 0;
          padding: 0;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          background-color: transparent;

          &:active {
            color: lightgrey;
          }
        }
      }

      div.graphic {
        position: absolute;
        background-color: #47b372;
        border-radius: 1px 50px 1px 50px;
        top: 200px;
        left: -15px;

        // debug
        // display: none;
        img {
          width: 470px;
          border-radius: 1px 50px 1px 50px;
          padding: 15px;
          padding-bottom: 11px;
          height: auto !important;
        }
      }

      div.products {
        position: absolute;
        top: 250px;
        right:12px;
        display: flex;
        padding: 15px;
        border-radius: 1px 15px 1px 15px;

        background-color: #1d4f48;

        // debug
        // display: none;

        a {
          position: relative;
          margin: 0 7.5px;
          margin-bottom: 35px;
          background-color: white;
          height: 90px;
          width: 90px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          text-decoration: none;

          img {
            max-width: 100% !important;
            aspect-ratio: 1/1;
          }

          span {
            font-family: sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 9pt;
            text-align: center;
            position: absolute;
            bottom: -35px;
            height: 35px;
            width: 90px;
            max-width: 90px;
            word-break: break-word;
            background-color: #47b372;
            color: white;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }

          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    // adjustments for really big desktops
    @media (min-width: 1000px) {
      div.products {
        a {
          height: 110px;
          width: 110px;

          span {
            width: 110px;
            max-width: 110px;
          }
        }
      }
    }
  }
}

.products.custom-right {
  right: 120px !important;
}

.products.two-right{
  right: 240px !important;
}
.products.one-right{
  right: 365px !important;
}